<template>
  <div>
    <div
      v-if="route.path !== '/invoices' && route.path !== '/wallet' && route.name !== 'confirm'"
      class="fixed bottom-0 left-0 w-full h-[82px] grid md:hidden place-items-center px-5 z-50"
    >
      <div class="w-full flex items-end justify-between z-10">
        <div class="w-[40%] flex items-center justify-between">
          <RouterLink to="/dashboard" exact-active-class="!text-primary-500" class="text-blue-dark-300 w-1/2 pr-4 py-4">
            <div class="w-10 flex flex-col items-center gap-y-1 m-auto pl-2">
              <BaseIcon icon="dashboard" :fill="route.path === '/dashboard'" />
              <span class="font-bold text-xs">داشبورد</span>
            </div>
          </RouterLink>
          <RouterLink to="/cards" exact-active-class="!text-primary-500" class="text-blue-dark-300 w-1/2 pr-4 py-4">
            <div class="w-10 flex flex-col items-center gap-y-1 m-auto pl-4">
              <BaseIcon size="24" icon="receipt" :fill="route.path === '/cards'" />
              <span class="font-bold text-xs">سفارش‌ها</span>
            </div>
          </RouterLink>
        </div>
        <RouterLink to="/store" exact-active-class="!text-primary-500" class="text-blue-dark-300 px-4 py-4">
          <div
            class="absolute left-1/2 -translate-x-1/2 top-[-24px] z-10 w-12 h-12 grid place-items-center rounded-full"
            :class="route.path === '/store' ? 'bg-primary-500' : 'bg-white border-[1px]'"
          >
            <BaseIcon
              icon="store"
              class="!text-[32px]"
              :class="route.path === '/store' ? 'text-white' : 'text-blue-dark-400'"
            />
          </div>
          <div class="flex flex-col h-full">
            <span class="font-bold text-xs">فروشگاه</span>
          </div>
        </RouterLink>

        <div class="w-[40%] flex items-center justify-between">
          <RouterLink to="/orders" exact-active-class="!text-primary-500" class="text-blue-dark-300 w-1/2 px-4 py-4">
            <div class="flex flex-col items-center gap-y-1 m-auto pr-6">
              <BaseIcon icon="local-shipping" :fill="route.path === '/orders'" />
              <span class="font-bold text-xs">بارها</span>
            </div>
          </RouterLink>
          <div class="w-1/2 flex flex-col items-center gap-y-1 text-blue-dark-300 m-auto px-4 py-4" @click="moreItemsMenu = true">
            <div v-if="authStore.userInfo.avatar_url" :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }" class="w-[24px] h-6 md:w-16 md:h-16 rounded-md cursor-pointer">
            </div>
            <BaseIcon v-else icon="menu" />
            <span class="font-bold text-xs">بیشتر</span>
          </div>
        </div>
      </div>
      <div class="absolute top-[1px] right-1/2 translate-x-1/2 w-full h-full">
        <img
          src="~@/assets/images/bg-mobile-menu.svg"
          class="w-full h-full object-cover drop-shadow-[0_0_12px_rgba(0,0,0,0.12)]"
        />
      </div>
    </div>
  </div>
  <MoreMenuBottomSheet v-model="moreItemsMenu" @log-out="emits('logOut')" />
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import MoreMenuBottomSheet from "./MoreMenuBottomSheet.vue";
import { ref } from 'vue';

const props = defineProps({
    authStore: {
        type: Object,
        required: true
    }
})
const emits = defineEmits(['logOut'])
const moreItemsMenu = ref(false);
const route = useRoute();

</script>

<style lang="scss" scoped>


</style>