import { useAuthStore } from "@/stores/authStore";
import axios, { type CancelToken } from "axios";
import { toast, type ToastOptions } from "vue3-toastify";

import { computed } from 'vue'

const companyId = computed(() => {
  const company =  localStorage.getItem('company')
  return company
})
export enum Method {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  PATCH = "patch",
}

const baseUrl: string = import.meta.env.VITE_API_BASE_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response) {
      const status = error.response.status;
      if (status === 401) {
        // toast(error?.response?.data?.message, {
        //   type: "error",
        //   position: toast.POSITION.TOP_RIGHT,
        // } as ToastOptions);
        useAuthStore().logout();
      } else if (status === 500) {
        toast("خطای سرور", {
          type: "error",
          position: toast.POSITION.TOP_RIGHT,
        } as ToastOptions);
      } else {
        toast(error?.response?.data?.message || error.response.data.msg || "error", {
          type: "error",
          position: toast.POSITION.TOP_RIGHT,
        } as ToastOptions);
      }
    }
    return Promise.reject(error);
  }
);

export async function Api<T>(
  method: Method,
  url: string,
  params: object | null,
  body: object | null,
  cancelToken?: CancelToken,
  uploadProgress?: (progressEvent: any) => void
) {
  // if (token) {
  //     const newToken = await needRefreshToken();
  //     token = newToken;
  // }

  const token = useAuthStore().token;

  return await axios.request<T>({
    method,
    url: url,
    baseURL: baseUrl,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      'SWITCH-TO-COMPANY': companyId.value
    },
    data: body,
    cancelToken: cancelToken,
    onUploadProgress: uploadProgress,
  });
}

export async function ApiWithoutToken<T>(
  method: Method,
  url: string,
  params?: object | null,
  body?: object | null,
  userAgent?: string
) {
  return await axios.request<T>({
    method,
    url: url,
    baseURL: baseUrl,
    headers: {
      "Agent-Info": userAgent,
      'SWITCH-TO-COMPANY': companyId.value
    },
    params,
    data: body,
  });
}
