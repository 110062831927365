import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/layouts/DashboardLayout/index.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "root",
      redirect: "/dashboard",
      meta: {
        layout: DashboardLayout,
      },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          meta: {
            title: "داشبورد",
          },
          component: () => import("@/views/dashboard/index.vue"),
        },
        {
          path: "/store",
          name: "store",
          meta: {
            title: "فروشگاه",
          },
          component: () => import("@/views/store/index.vue"),
        },
        {
          path: "/store/confirm/:id",
          name: "confirm",
          meta: {
            title: "خرید محصول",
          },
          component: () => import("@/views/store/confirm.vue"),
        },
        {
          path: "/cards",
          name: "cards",
          meta: {
            title: "سفارش‌ها ",
          },
          component: () => import("@/views/cards/index.vue"),
        },
        {
          path: "/orders",
          name: "orders",
          meta: {
            title: "سفارش‌ها",
          },
          component: () => import("@/views/orders/index.vue"),
        },
        {
          path: "/invoices",
          name: "invoices",
          meta: {
            title: "صورت حساب ها",
          },
          component: () => import("@/views/invoices/index.vue"),
        },
        {
          path: "/user-account",
          name: "user-account",
          meta: {
            title: "حساب کاربری",
          },
          component: () => import("@/views/user/acount.vue"),
        },
        {
          path: "/wallet",
          name: "wallet",
          meta: {
            title: "کیف پول",
          },
          component: () => import("@/views/wallet/index.vue"),
        },
        // {
        //   path: "/test",
        //   name: "test",
        //   meta: {
        //     title: "تست",
        //   },
        //   component: () => import("@/views/test/index.vue"),
        // },
      ],
    },
    {
      path: "/auth",
      name: "auth",
      meta: {
        title: "ورود",
      },
      component: () => import("@/views/auth/index.vue"),
    },
    {
      path: "/payment/status",
      name: "payment-status",
      meta: {
        title: "وضعیت پرداخت",
      },
      component: () => import("@/views/payment-status/index.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = (to.meta?.title as string) ?? "سیمان آینده";
  next();
});

export default router;
