import { Api, Method } from "../api";
import type {
  RentInfoResponse,
  StoreInfoResponse,
  StoreNewCardRequest,
} from "./store.types";
import type { IResponse } from "@/core/models/IResponse";

export const apiGetStoreFormData = () => {
  return Api<IResponse<StoreInfoResponse>>(
    Method.GET,
    "/shop/get_data_set_card",
    null,
    null
  );
};

export const apiSaveUserCustomer = (body: any) => {
  return Api<any>(
    Method.POST,
    "/user_customer/store_user_customer",
    null,
    body
  );
};
export const apiGetUserCustomer = () => {
  return Api<any>(
    Method.GET,
    "/user_customer/get",
    null,
    null
  );
};
export const apiDeleteUserCustomer = (id: any) => {
  return Api<any>(
    Method.GET,
    `/user_customer/delete_user_customer?user_customer_id=${id}`,
    null,
    null
  );
};
export const apiGetCityRent = (params: any) => {
  return Api<IResponse<RentInfoResponse>>(
    Method.GET,
    "/shop/get_city_rent",
    params,
    null
  );
};

export const apiGetPriceInfo = (params: any, cancelToken?: any) => {
  return Api<any>(
    Method.GET,
    "/shop/get_price_per_ton",
    params,
    null,
    cancelToken
  );
};
export const apiGetFactories = () => {
  return Api<IResponse<RentInfoResponse>>(
    Method.GET,
    "/shop/get_factories_and_products",
    null,
    null
  );
};
export const apiSaveNewCard = (body: StoreNewCardRequest) => {
  return Api<{ message: string; card: any }>(
    Method.POST,
    "/card/store_new_card",
    null,
    body
  );
};
export const apiCreatePreInvoice = (body: StoreNewCardRequest) => {
  return Api<any>(
    Method.POST,
    "/proforma_invoice/store",
    null,
    body
  );
};

export const apiGetPreInvoice = (id: any) => {
  return Api<any>(
    Method.GET,
    `/proforma_invoice/get_one?id=${id}`,
    null,
    null
  );
};
export const apiGetPreInvoicesList = () => {
  return Api<any>(
    Method.GET,
    "proforma_invoice/get_list?response_type=1",
    null,
    null
  );
};
export const apiRemovePreInvoice = (body: any) => {
  return Api<any>(
    Method.POST,
    "/proforma_invoice/remove",
    null,
    body
  );
};
export const apiCompletePurchase = (body: any) => {
  return Api<any>(
    Method.POST,
    "/shop/complete_purchase",
    null,
    body
  );
};
export const apiFavoriteProduct = (body: any) => {
  return Api<any>(
    Method.POST,
    "/shop/change_favorite_product",
    null,
    body
  );
};
export const apiDownloadPreInvoice = (id: any) => {
  return Api<any>(Method.GET, `proforma_invoice/download_pdf?proforma_invoice_id=${id}`, null, null);
};
